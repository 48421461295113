import { Grid, IconButton, Tooltip } from '@mui/material';
import { CustomCellRendererProps } from 'ag-grid-react';
import { ElementType, FC, useMemo } from 'react';

interface ActionsRendererProps {
  actions: {
    tooltip: string;
    Icon: ElementType;
    color?: 'success' | 'error' | 'primary';
    disabled?: (data: any) => boolean;
    onAction?: (data: any) => void;
    onlyFirstRow?: boolean;
  }[];
  disabled?: boolean;
}

const ActionsRenderer: FC<CustomCellRendererProps & ActionsRendererProps> = ({
  node,
  data,
  actions,
  disabled,
}) => {
  const actionsViews = useMemo(() => {
    return actions.map(
      (
        { Icon, color, tooltip, disabled: disabledFn, onAction, onlyFirstRow },
        i
      ) => {
        let disableAction = disabled || disabledFn?.(data);
        if (onlyFirstRow && node.rowIndex !== 0) {
          disableAction = true;
        }
        return (
          <Grid item key={i}>
            <Tooltip title={tooltip}>
              <IconButton
                size="small"
                onClick={() => onAction?.(data)}
                color={color}
                disabled={disableAction}
              >
                <Icon />
              </IconButton>
            </Tooltip>
          </Grid>
        );
      }
    );
  }, [actions, data, disabled, node.rowIndex]);

  return (
    <Grid container direction="row" spacing={1} wrap="nowrap">
      {actionsViews}
    </Grid>
  );
};

export default ActionsRenderer;
