const locale: {
  agGrid: Record<string, string>;
} = {
  agGrid: {
    page: 'Página',
    to: 'a',
    of: 'de',
    more: 'muchas',
    filterOoo: 'Filtrar...',
    equals: 'Igual a',
    notEqual: 'No igual a',
    contains: 'Contiene',
    notContains: 'No contiene',
    startsWith: 'Empieza con',
    endsWith: 'Termina con',
    blank: 'Nulo',
    notBlank: 'No nulo',
    andCondition: 'Y',
    orCondition: 'O',
    copy: 'Copiar',
    export: 'Exportar',
    cut: 'cortar',
    paste: 'Pegar',
    pageSizeSelectorLabel: 'Registros por página',
    noRowsToShow: 'No hay registros para mostrar',
  },
};

export const generateLabels = (
  typeEnum: Record<any, any>,
  localeEnum: Record<any, string>
) => {
  return Object.keys(typeEnum)
    .map((key) => {
      if (!isNaN(parseInt(key, 10))) {
        return null;
      }
      const value = typeEnum[key as keyof typeof typeEnum];
      return {
        value,
        label: `${localeEnum[value]}`,
      };
    })
    .filter((d) => Boolean(d));
};

export default locale;
