import React, { useCallback } from 'react';
import PaginatedTable, { PaginationProps } from './PaginatedTable';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';

import 'ag-grid-enterprise';
import { UseMutationResult } from '@tanstack/react-query';
import { IGetUsersResponse, IUser } from 'permisos-common';
interface IUsersDataTableProps {
  getUsersMutation: UseMutationResult<
    IGetUsersResponse,
    Error,
    PaginationProps,
    unknown
  >;
  columnDefs: ColDef[];
  gridRef: React.MutableRefObject<GridApi | null>;
}

const LOG_DEFAULT_COL_DEF: ColDef = {
  sortable: false,
};

export const UsersDataTable = (props: IUsersDataTableProps) => {
  const { getUsersMutation, columnDefs, gridRef } = props;

  const onGridReady = useCallback(
    (e: GridReadyEvent) => {
      gridRef.current = e.api;
    },
    [gridRef]
  );

  return (
    <PaginatedTable<IUser, IGetUsersResponse>
      defaultPageSize={15}
      columnDefs={columnDefs}
      getRows={getUsersMutation}
      filters={[]}
      defaultColDef={LOG_DEFAULT_COL_DEF}
      onGridReady={onGridReady}
    />
  );
};
