export const parseFilters = (filters: Record<string, any>) => {
  const result: Record<string, any> = {};

  Object.keys(filters).forEach((key: any) => {
    const value = filters[key];
    result[key] = value.filter || value.values || value.dateFrom;
  });
  return JSON.stringify(result);
};

export const parseSort = (orders: Record<string, any>) => {
  const result: Record<string, any> = {};

  orders.forEach((key: any) => {
    result[key.colId] = key.sort || null;
  });
  return JSON.stringify(result);
};
