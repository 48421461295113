import { QueryFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import {
  GetUserResponseDto,
  GetUserRolesResponseDto,
  UpdateUserRequestDto,
} from 'permisos-common';

import api from '../api';
import { IGetUsers, IGetUsersResponse } from 'permisos-common';
import { parseFilters, parseSort } from '../../utils/parseDataHttp';

export const getUsers = async ({
  page,
  pageSize,
  filters,
  sort,
}: {
  page: number;
  pageSize: number;
  filters?: any;
  sort?: any;
}): Promise<IGetUsersResponse> => {
  const parsedFilters = parseFilters(filters);
  const order = parseSort(sort);
  const response = await api.get(`/users`, {
    params: {
      page,
      pageSize,
      filters: parsedFilters,
      order,
    },
  });
  return response.data;
};

export const updateUser = async ({
  username,
  userData,
}: {
  username: string;
  userData: UpdateUserRequestDto;
}) => {
  return api
    .put<
      UpdateUserRequestDto,
      AxiosResponse<GetUserResponseDto>
    >(`/users/${username}`, userData)
    .then((r) => r.data);
};

export const getUserRoles: QueryFunction<GetUserRolesResponseDto> = async ({
  queryKey,
}) => {
  const [, username] = queryKey;
  if (!username) {
    return null;
  }

  return api.get(`/users/${username}/roles`).then((r) => r.data);
};
