import { Grid, Typography, ThemeProvider } from '@mui/material';
import { NextPage } from 'next';
import { useMemo, useRef, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Policy, Forbidden } from 'ui';
import theme from '../styles/theme';

import useUser from '../auth/useUser';
import { getUsers } from '../http/api/users';
import { EditUserModal } from '../components/editUserModal';
import { updateUser } from '../http/api/users';
import { IUser } from 'permisos-common';
import { UsersDataTable } from '../components/usersDataTable';
import { FormSubmitSnackbar } from '../components/formSubmitSnackbar';
import ActionsRenderer from '../components/CellRenderers/ActionsRenderer';
import {
  ColDef,
  GridApi,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { EditOutlined } from '@mui/icons-material';
import { DateTime } from 'luxon';

const UsersPage: NextPage = () => {
  const currentUser = useUser();
  const gridRef = useRef<GridApi | null>(null);

  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [openEditUserFormSubmitAlert, setOpenEditUserFormSubmitAlert] =
    useState(false);

  const hasManagePermission = currentUser?.roles.some(
    (permission) => permission === 'permisos/manage_permissions'
  );

  const queryClient = useQueryClient();

  const onEditUser = (user: IUser) => {
    setIsEditUserDialogOpen(true);
    setSelectedUser(user);
  };

  const editUserMutation = useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUsers'] });
      queryClient.invalidateQueries({ queryKey: ['getUserRoles'] });
      setOpenEditUserFormSubmitAlert(true);
      gridRef.current?.refreshServerSide();
    },
  });

  const formatLastLoginBody = (timestamp?: number | null) => {
    if (!timestamp) {
      return '-';
    }

    const dateTime = DateTime.fromMillis(timestamp).setZone('America/Bogota');
    return dateTime.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
  };

  const columnDefs: ColDef<any>[] = useMemo(
    () => [
      {
        field: 'username',
        headerName: 'Nombre de usuario',
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          maxNumConditions: 1,
        },
        sortable: true,
      },
      {
        field: 'osfUsername',
        headerName: 'Nombre de usuario Osf',
        cellRenderer: (params: ICellRendererParams) => {
          if (!params?.value || params?.value === '') {
            return '--';
          }
          return params?.value;
        },
        sortable: false,
      },
      {
        field: 'onbaseUsername',
        headerName: 'Nombre de usuario OnBase',
        cellRenderer: (params: ICellRendererParams) => {
          if (!params?.value || params?.value === '') {
            return '--';
          }
          return params?.value;
        },
        sortable: false,
      },
      {
        field: 'lastLogin',
        headerName: 'Último ingreso',
        minWidth: 190,
        filter: 'agDateColumnFilter',
        sortable: true,
        valueGetter: (params: ValueGetterParams) => {
          const lastLogin = params.data?.lastLogin;
          if (!lastLogin) {
            return null;
          }

          const date = new Date(lastLogin);
          return isNaN(date.getTime()) ? null : date.getTime();
        },
        valueFormatter: (params) => formatLastLoginBody(params.value),
        filterParams: {
          filterOptions: ['equals'],
          maxNumConditions: 1,
        },
      },
      {
        headerName: 'Acciones',
        cellRenderer: ActionsRenderer,
        resizable: false,
        sortable: false,
        maxWidth: 150,
        minWidth: 150,
        width: 150,
        filter: false,
        cellRendererParams: {
          actions: [
            {
              Icon: () => (
                <EditOutlined
                  style={{
                    color: hasManagePermission ? '#1976D2' : 'primary',
                  }}
                />
              ),
              tooltip: 'Editar',
              disabled: () => !hasManagePermission,
              onAction: onEditUser,
            },
          ],
        },
      },
    ],
    [hasManagePermission]
  );

  const getUsersMutation = useMutation({
    mutationFn: getUsers,
  });

  return (
    <Policy
      user={currentUser}
      roles={['manage_permissions']}
      fallback={
        <Forbidden
          text={'No tienes permiso para acceder a la aplicación'}
          userLogout={currentUser?.actions?.logout}
          email={currentUser?.email}
        />
      }
    >
      <ThemeProvider theme={theme}>
        <Grid
          container
          sx={{
            background: theme.palette.background.paper,
            boxShadow: theme.shadows[4],
            borderRadius: 1,
            marginTop: 2,
            padding: 2,
          }}
          rowSpacing={2}
          columnSpacing={2}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
              variant="h5"
              component="h2"
            >
              Usuarios
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ width: '100%' }}>
            <UsersDataTable
              columnDefs={columnDefs}
              getUsersMutation={getUsersMutation}
              gridRef={gridRef}
            />
          </Grid>
        </Grid>
        <EditUserModal
          isOpen={isEditUserDialogOpen}
          toggleOpen={setIsEditUserDialogOpen}
          selectedUser={selectedUser}
          editUserMutation={editUserMutation}
        />
        <FormSubmitSnackbar
          isOpen={openEditUserFormSubmitAlert}
          onClose={() => setOpenEditUserFormSubmitAlert(false)}
          severity="success"
          text={'¡Usuario editado exitosamente!'}
        />
      </ThemeProvider>
    </Policy>
  );
};

export default UsersPage;
