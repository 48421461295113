import { useCallback, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  GridReadyEvent,
  IServerSideDatasource,
  PaginationChangedEvent,
  RowClickedEvent,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { UseMutationResult } from '@tanstack/react-query';
import { GetRowsResponse } from 'permisos-common';
import { defaultGridOptions } from '../utils/grid/defaultGridOptions';
import { Box } from '@mui/material';
import { RowStyle } from 'ag-grid-community';
import { RowClassParams } from 'ag-grid-community';

export type PaginationProps = {
  page: number;
  pageSize: number;
  filters?: any;
  sort?: any;
};

type PaginatedTableProps<T, R extends GetRowsResponse<T>> = {
  defaultPageSize: number;
  columnDefs: ColDef[];
  defaultColDef?: ColDef;
  getRows: UseMutationResult<R, Error, PaginationProps, unknown>;
  filters: object;
  handleRowClick?: (row: RowClickedEvent) => void;
  getRowStyle?: (params: RowClassParams) => RowStyle | undefined;
  onGridReady?: (e: GridReadyEvent) => void;
};

const defaultColDefinition = {
  flex: 1,
  sortable: true,
  filter: true,
  resizable: true,
  suppressMovable: true,
  floatingFilter: true,
  suppressHeaderMenuButton: true,
};

const PaginatedTable = <T, R extends GetRowsResponse<T>>({
  defaultPageSize,
  columnDefs,
  defaultColDef,
  getRows,
  filters,
  handleRowClick,
  getRowStyle,
  onGridReady,
}: PaginatedTableProps<T, R>) => {
  const [customPageSize, setCustomPageSize] = useState<number>(defaultPageSize);
  const baseParams = useMemo(() => {
    return {
      pageSize: customPageSize,
      columnDefs,
      defaultColDef: {
        ...defaultColDefinition,
        ...defaultColDef,
      },
      getRows,
      filters,
      handleRowClick,
    };
  }, [customPageSize, columnDefs]);

  const paginationPageSizeSelector = useMemo(() => {
    const sizes = new Set([defaultPageSize, 20, 50, 100]);
    return Array.from(sizes);
  }, [defaultPageSize]);

  const dataSource = useMemo<IServerSideDatasource>(
    () => ({
      getRows: async ({
        request: {
          startRow = 0,
          endRow = 15,
          filterModel = {},
          sortModel = [],
        },
        success,
        fail,
      }) => {
        const perPage = endRow - startRow;
        const page = Math.floor(startRow / perPage) + 1;

        try {
          const response = await baseParams.getRows.mutateAsync({
            ...baseParams.filters,
            page,
            pageSize: perPage,
            filters: filterModel,
            sort: sortModel,
          });

          success({
            rowData: response?.rows ?? [],
            rowCount: response?.count ?? 0,
          });
        } catch (error) {
          fail();
        }
      },
    }),
    [baseParams.getRows, baseParams.filters]
  );

  const onPaginationChanged = useCallback((e: PaginationChangedEvent) => {
    if (e.newPageSize) {
      setCustomPageSize(e.api.paginationGetPageSize());
    }
  }, []);

  const gridOptions = useMemo(
    () => ({ ...defaultGridOptions, serverSideStoreType: 'partial' }),
    []
  );

  return (
    <Box className="ag-theme-quartz" sx={{ width: '100%', height: 800 }}>
      <AgGridReact
        serverSideDatasource={dataSource}
        paginationPageSizeSelector={paginationPageSizeSelector}
        gridOptions={gridOptions}
        columnDefs={baseParams.columnDefs}
        defaultColDef={baseParams.defaultColDef}
        enableCellTextSelection={true}
        animateRows={true}
        pagination={true}
        onRowClicked={baseParams.handleRowClick}
        domLayout="normal"
        rowModelType="serverSide"
        paginationPageSize={baseParams.pageSize}
        getRowStyle={getRowStyle}
        onGridReady={onGridReady}
        onPaginationChanged={onPaginationChanged}
      />
    </Box>
  );
};

export default PaginatedTable;
